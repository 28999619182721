import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ theme, children, disabled, type }) => {
  return (
    <button type={type} disabled={disabled}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
}

export default Button;